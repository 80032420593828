<template>
    <div id="messages" class="d-flex-c-ast-jfs">
        <h2 class="p-30">پیام ها</h2>
        <div class="messages d-flex-c-ast-jfs-gap10">
            <the-collapsible v-for="(item,index) in data" :key="index" ref="collapsible" :class="{deactive : n>2}"
                             :item="item"/>
        </div>
    </div>
</template>

<script>
    import TheCollapsible from "@/components/Tools/TheCollapsible";

    export default {
        name: "Messages",
        components: {TheCollapsible},
        data() {
            return {
                data: [
                    {
                        header: 'ورود موفق',
                        time: '99/2/12 12:34:00',
                        desc: 'کاربری در تاریخ 3 خرداد ماه 1399 ساعت 10 و 46 دقیقه و 24 ثانیه با اطلاعات کاربری شما وارد سامانه شد، لطفا اگر احساس می کنید این ورود توسط شما نبوده است ، نسبت به تعویض رمز عبور خود در اولین فرصت اقدام نمایید.'
                    },
                    {
                        header: 'ورود موفق',
                        time: '99/2/12 12:34:00',
                        desc: 'کاربری در تاریخ 3 خرداد ماه 1399 ساعت 10 و 46 دقیقه و 24 ثانیه با اطلاعات کاربری شما وارد سامانه شد، لطفا اگر احساس می کنید این ورود توسط شما نبوده است ، نسبت به تعویض رمز عبور خود در اولین فرصت اقدام نمایید.'
                    },
                    {
                        header: 'ورود موفق',
                        time: '99/2/12 12:34:00',
                        desc: 'کاربری در تاریخ 3 خرداد ماه 1399 ساعت 10 و 46 دقیقه و 24 ثانیه با اطلاعات کاربری شما وارد سامانه شد، لطفا اگر احساس می کنید این ورود توسط شما نبوده است ، نسبت به تعویض رمز عبور خود در اولین فرصت اقدام نمایید.'
                    },
                    {
                        header: 'ورود موفق',
                        time: '99/2/12 12:34:00',
                        desc: 'کاربری در تاریخ 3 خرداد ماه 1399 ساعت 10 و 46 دقیقه و 24 ثانیه با اطلاعات کاربری شما وارد سامانه شد، لطفا اگر احساس می کنید این ورود توسط شما نبوده است ، نسبت به تعویض رمز عبور خود در اولین فرصت اقدام نمایید.'
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    #messages {
        padding: 0 20px 20px;
        text-align: right;
    }
</style>